<template>
    <div>
        <form @submit.prevent="updateRecord">

            <!-- paste from hq below here -->

            <div class="">
                <h1>Alert</h1>
                <input type='hidden' id='id' name='id' :value='record_id' readonly='readonly' />
                <div>
                    <Checkbox
                        class="flex center bottom-15"
                        :value="record_data.active"
                        v-model="record_data.active"
                        label="Active"
                        :disabled="!isEditable"
                    /></div>
                <div class="flex colx4 wrap">
                    <div><Dropdown
                        v-model="record_data.alert_type"
                        id="alert_type"
                        name="alert_type"
                        label="Alert Type"
                        :options="[{text:'Missing Document', value:'missing_document'},{text:'Outstanding Balance', value:'outstanding_balance'},{text:'Client Action', value:'client_action'},{text:'Ready to Send', value:'ready_to_send'},]"
                        required
                        :readonly="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="alert"
                        id="alert"
                        placeholder="Alert"
                        v-model="record_data.alert"
                        label="Alert"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="client_id"
                        id="client_id"
                        placeholder="Client Id"
                        v-model="record_data.client_id"
                        label="Member Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="form_data_id"
                        id="form_data_id"
                        placeholder="Form Data Id"
                        v-model="record_data.form_data_id"
                        label="Form Data Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="date"
                        name="dayt_due"
                        id="dayt_due"
                        placeholder="Dayt Due"
                        v-model="record_data.dayt_due"
                        label="Dayt Due"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="client_alert"
                        id="client_alert"
                        placeholder="Client Alert"
                        v-model="record_data.client_alert"
                        label="Member Alert"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="page_id"
                        id="page_id"
                        placeholder="Page Id"
                        v-model="record_data.page_id"
                        label="Page Id"
                        :disabled="!isEditable"
                    /></div>
                </div>

                <!--form_extra-->
            </div>



            <ConfirmButton @click="updateRecord()"></ConfirmButton>
        </form>
    </div>
</template>
<script>
    const api_root = 'alerts'

    export default {
        name: 'AlertDetail',
        props: {
            record_id: [String, Number],
        },
        data() {
            return {
                record_data : {
                    active : 0,
                    alert_type : '',
                    alert : '',
                    client_id : 1,
                    form_data_id : 1,
                    dayt_due : 'dayt',
                    client_alert : 1,
                    page_id : 1,
                },

                isEditable : 1,

                record_actions : {
                    getRecordDetails: (id) => `/${api_root}/${id}`,
                    updateRecord: (id) => `/${api_root}/${id}`,
                }
            }
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id))

                    if (res.status === 404 || res.status === 500) {
                        this.$toasted.error('Could not find record')
                        return this.$router.replace({ name: 'NotFound' })
                    } else {
                        this.record_data = res.data.info[0]
                    }

                }
            },
            async updateRecord(e) {
                e.preventDefault()
                const isValid = await this.validateForm()

                if (isValid) {
                    try {
                        const res = await this.$api.put(this.record_actions.updateRecord(this.record_id), this.record_data)
                        const record_id = parseInt(res.data.recId[0])
                        this.$toasted.success('Successfully updated Record.')
                        await this.$router.push({ name: this.name, params: { record_id: record_id } })
                    } catch (err) {
                        this.$toasted.error('Failed to update Record. Please try again later.')
                    }
                }
            },
            async validateForm() {
                const isValid = 1 // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.')
                }

                return isValid
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            async $route() {
                await this.init()
            },
        },
    }
</script>
